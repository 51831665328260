.paymentSuccess {
	text-align: center;
	padding: 10px;
	background: #70d333;;
	color: white;
	border-radius: 10px;
}
.paymentFailed {
	text-align: center;
	padding: 10px;
	background: #c03030;
	color: white;
	border-radius: 10px;
}